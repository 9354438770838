import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import ContactForm from "../pages/contact/form";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import StockGainerPersonal from "../components/StockGainerPersonal";
import ChartTwo from "../components/ChartTwo";
import ChartBandLight from "../components/ChartBandLight";
import ChartThreeLight from "../components/ChartThreeLight";

const LegalPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => {
  const data = [{img: "../img/legal1.png" , text: "Complaints Handling Policy", pdffile:"../pdf/Swiftfox-Complaints-Handling-Policy.pdf"},{img: "../img/legal2.png" , text: "Customer Support Standards and Processes Policy",pdffile:"../pdf/Swiftfox-Customer-Support-Standards-and-Processes-Policy.pdf"},{img: "../img/legal3.png" , text: "Data Privacy and Data Processing Policy",pdffile:"../pdf/Swiftfox-Data-Privacy-and-Data-Processing-Policy.pdf"}
  ,{img: "../img/legal4.png" , text: "Information Security Policy",pdffile:"../pdf/Swiftfox-Information-Security-Policy.pdf"},{img: "../img/legal5.png" , text: "Vulnerable Clients Handling Policy",pdffile:"../pdf/Swiftfox-Vulnerable-Clients-Handling-Policy.pdf"}]
return (
  <div>
    <div className="hero-section faq-hero legalsectionheight">
      <div className="hero-main">
        <div className="hero-main-content ">
      <div className="section-item white-text">
          <InView threshold={0.1}>
            {({ ref, inView }) => (
              <motion.h1
                ref={ref}
                initial={{ opacity: 0 }}
                animate={inView ? { opacity: 1 } : { opacity: 1 }}
                transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }}
              >
                Corporate Governance <br />
                <span>& Compliance Policies</span>
              </motion.h1>
            )}
          </InView>
          <InView threshold={0.1}>
            {({ ref, inView }) => (
              <motion.h4
                ref={ref}
                initial={{ opacity: 0 }}
                animate={inView ? { opacity: 1 } : { opacity: 1 }}
                transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}
              >
                This section outlines the policies and practices that govern
                our operations and ensure accountability across our
                organization.{" "}
              </motion.h4>
            )}
          </InView>
        </div>
    
        </div>


        <div className="grid-container">
          {data.map((n , index) => (
            <div key={index} className="item-container">
               <a href={n.pdffile} target="_blank"><img src={n.img} className="item-image" alt="" /> </a>
              <div className="item-text white-text">
                <h4>{n.text}</h4>
              </div>
              <a href={n.pdffile} target="_blank"> <img src="../img/downloadicon.png" className="download-icon" alt=""/></a>
            </div>
          ))}
        </div>

      </div>
    </div>
    
    </div>
);
}

LegalPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const LegalPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <LegalPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

LegalPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default LegalPage;

export const pageQuery = graphql`
  query LegalPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`;
